import $ from 'jquery'
import Vue from 'vue'

import http from '@/custom/http'
import validation from '@/custom/validate'
import buefy from '@/custom/buefy'
import cleave from '@/custom/cleave'
import store from '@/store'

/**
 * если включать роутер по-умолчанию,
 * то ко всем url будет добавляться `#/`,
 * что не всегда является приемлемым
 */
//import router from './router'

/**
 * *** add libs above this line ***
 *
 * main.js - это главный файл проекта, точка
 * подключения всех библиотек, компонентов и стилей
 *
 * здесь происходит глобальная инициализация, если
 * этого нельзя сделать в компонентах.
 *
 * Лучше если в файле будет минимум кода. Всегда
 * анализировать на предмет оптимизации и разделения на отдельные файлы.
 *
 * Стараться хранить инициализацию в конкретных компонентах.
 * Например в индексном файле компонента.
 */

import { FeedbackForm, StatusBar } from './components'
import callbackForm from '@/components/site/callback'
import contactsForm from '@/components/site/contacts'
import navbar from './runtime/navbar/index'
import swiperHero from './runtime/swiper-hero'
import swiperIndexServices from './runtime/swiper-index-services'
import swiperGalleryCatalog from './runtime/swiper-gallery-catalog'
import swiperCatalogWork from './runtime/swiper-catalog-work'
import hasBackgroundImage from './runtime/background-images'
import tabsElemCatalog from './runtime/tabs-elem-catalog'
import worksMenuBrand from './runtime/works-menu-brand'
import orderToService from '@/components/site/order-to-service'
import orderToSmash from '@/components/site/order-to-smash'
import pswp from '@/runtime/pswp'
/**
 * стили проекта (./sass/)
 *
 * при сборке `npm run build` будут извлечены в два
 * одинаковых файла в шаблоне `template_style.css` и
 * `css/app.css` (это одинаковые файлы), битрикс подключит
 * файл `template_style.css` автоматически.
 */
import './sass/main.sass'

Vue.config.productionTip = false

window.jQuery = window.$ = $

/**
 * пример создания приложений
 * необходимо проверять существует ли элемент
 * на странице перед монтированием
 *
 * можно ждать загрузки страницы,
 * но можно и не ждать если скрипты
 * подключаются внизу страницы
 *
 * сами компоненты можно воспринимать
 * как виджеты, внедрения на страницу
 *
 * Можно подключаться к готовым элементам
 * страницы, например формам или слайдерам
 *
 * Код инициализации размещен только для примера.
 * Лучше когда инициализация будет в самом компоненте.
 *
 * @todo cleanup
 *
 */

http.init()
validation.init()
buefy.init()
cleave.init()

document.addEventListener('DOMContentLoaded', () => {
  const feedback = document.querySelector('#feedback-form')

  if (feedback) {
    new Vue({
      store,
      render: h => h(FeedbackForm)
    }).$mount(feedback)
  }

  const statusBar = document.getElementById('platform-status-bar')

  if (statusBar) {
    new Vue({
      store,
      render: h => h(StatusBar)
    }).$mount(statusBar)
  }

 /* const wrapper = document.createElement('div')
  wrapper.id = 'side-panel'
  document.body.appendChild(wrapper)

  new Vue({
    store,
    render: h => h(SidePanel)
  }).$mount(wrapper)
*/
  // widgets
  orderToService.start()
  orderToSmash.start()
  callbackForm.start()
  contactsForm.start()

  // runtime
  navbar.start()
  swiperHero.start()
  swiperIndexServices.start()
  swiperGalleryCatalog.start()
  swiperCatalogWork.start()
  hasBackgroundImage.start()
  tabsElemCatalog.start()
  worksMenuBrand.start()
    /**
   * runtime version PhotoSwipe
   * runtime версии требуется чтобы
   * шаблон был подключен в `footer.php`
   */
  pswp.start()

})
