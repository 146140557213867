<template lang="pug">
  .platform-status-bar
    // p {{message}}
    button.button(:class="{'is-loading is-danger': isLock}") lock?
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      message: 'bar'
    }
  },
  computed: {
    ...mapGetters(['isLock'])
  }
}
</script>
<style lang="sass" scoped>
  .platform-status-bar
    background: red
    color: #fff
</style>
