/**
 * важно! стили подключаются в src/sass/main.sass
 */
import Vue from 'vue'
import {
  ConfigProgrammatic,
  Button,
  Field,
  Icon,
  Input,
  Numberinput,
  Radio,
  Select,
  Slider,
  Tabs,
  Modal,
  Toast,
  Notification
} from 'buefy'

function init() {
  Vue.use(Numberinput)
  Vue.use(Icon)
  Vue.use(Tabs)
  Vue.use(Select)
  Vue.use(Input)
  Vue.use(Field)
  Vue.use(Slider)
  Vue.use(Radio)
  Vue.use(Toast)
  Vue.use(Modal)
  Vue.use(Notification)
  Vue.use(Button)

  /**
   * @see https://buefy.org/documentation/constructor-options
   */
  ConfigProgrammatic.setOptions({
    defaultIconPack: 'fas',
    defaultDialogCancelText: 'Отменить'
    // defaultDialogConfirmText: 'OK'
  })
}

export default {
  init
}
