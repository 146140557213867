<template lang="pug">
  // форма запроса обратного звонка
  .site-callback-form.section: validation-observer(v-slot="{valid, invalid}")
    p.title.is-4 Заказать сигнализацию
    b-notification.is-success(v-if="isSuccess" :closable="false")
      p.title.is-4 Спасибо! Ваша заявка отправлена.
      p.success-message как только получим вашу заявку, обязательно перезвоним
      p: button.button(@click.prevent="closeSuccess") понятно
    b-notification.is-danger(v-if="hasErrors")
      p.title.is-4 Ошибка!
      p не удалось обработать запрос, попробуйте еще раз, если ошибка повторяется перезагрузите страницу
    form.form(@submit.prevent="submit" ref="or_form" name="or_form" v-if="isNotSuccess")
      input(type="hidden" name="WEB_FORM_ID" value="7")
      input(type="hidden" name="form_text_62" value="да")
      .columns.is-multiline
        .column.is-half: validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required" name="Имя и фамилия")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_text_56" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия *")
          p.help.is-danger(v-if="invalid")  {{ errors[0] }}  
        
        .column.is-half: validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required" name="Телефон")
          b-field.is-expanded(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            .control: b-button.button.is-static +7
            b-input.is-fullwidth.is-expanded(name="form_text_57" :disabled="isLock" v-model="formData.phone" placeholder="000 000 00 00" v-cleave="{phone: true, numericOnly: true, phoneRegionCode: 'RU'}")
          p.help.is-danger(v-if="invalid") {{ errors[0] }} 
        
        .column.is-half: validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required" name="Марка автомобиля")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_text_58" :disabled="isLock" v-model="formData.brand" placeholder="Марка автомобиля *")
          p.help.is-danger(v-if="invalid")  {{ errors[0] }} 
          
        .column.is-half: validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required" name="Модель автомобиля")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_text_59" :disabled="isLock" v-model="formData.car" placeholder="Модель автомобиля *")
          p.help.is-danger(v-if="invalid")  {{ errors[0] }}  
          
        .column.is-half: validation-provider(v-slot="{errors}" rules="required" name="Вариант")
          label.radio
            input(type="radio" id="60" name="form_radio_or_install" value="60" v-model="formData.install")
          label(for="60") &nbsp; Заказать с установкой
          br
          label.radio
            input(type="radio" id="61" name="form_radio_or_install" value="61" v-model="formData.install")
          label(for="60") &nbsp; Заказать без установки
          p.help.is-danger  {{ errors[0] }} Выберите вариант
        .column.is-narrow
          b-field
            button.button.is-primary(type="submit" :disabled="isLock || invalid" :class="{ 'is-loading': isLock }") отправить
      p Заполните и отправьте свои контактные данные, <strong>мы перезвоним Вам</strong>.
      p.help.
        Отправляя форму, Вы принимаете <a href="https://docs.google.com/document/d/1viTlWxg8VNGRd1ylIkJvWyIRztEqBEwNGgWmzJI8Zrw/edit" target="_blank">Положение на обработку персональных данных и получение информации</a>. Передаваемая
        контактная информация используется исключительно для связи с Вами.

</template>
<script>
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
export default {
  store,
  data () {
    return {
      status: 'pristine',
      formId: 7,
      formData: {
        name: '',
        brand: '',
        car: '',
        install: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapGetters(['isLock']),
    isSuccess() {
      return this.status === 'success'
    },
    hasErrors() {
      return this.status === 'error'
    },
    isNotSuccess() {
      return this.status !== 'success'
    }
  },
  methods: {
    ...mapMutations(['lock', 'unlock']),
    submit () {
      this.lock()
      const form = new FormData(this.$refs.or_form)
      this.$http.post('/platform/api/forms/7/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.unlock()
        this.status = 'success'
        this.formData.name = ''
        this.formData.phone = ''
        this.formData.brand = ''
        this.formData.car = ''
        this.formData.install = ''

        this.$parent.$emit('success')
      }, errors => {
        this.unlock()
        this.status = 'error'
        this.$buefy.toast.open({
          type: 'is-danger',
          message: `Ошибка! ${errors.status}`
        })
      })
    },
    closeSuccess() {
      this.status = 'pristine'
      this.$emit('close')
    }
  }
}
</script>
<style lang="sass" scoped>
@import 'vars'
.site-callback-form.section
  padding: 1.4rem
.site-callback-form
  background: #fff
  border-radius: 6px
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem

</style>
