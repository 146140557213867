import Vue from 'vue'
import store from '@/store'
import CallbackFormTrigger from '@/components/site/callback/CallbackFormTrigger';

function start() {
  /**
   * поиск всех элементов на странице
   * и создание виджетов
   */
  document
    .querySelectorAll('.widget-callback-trigger')
    .forEach((el) => {
      new Vue({
        el: el,
        store,
        render: h => h(CallbackFormTrigger)
      })
  })
}

export default {
  start
}
