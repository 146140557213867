import Vue from 'vue'
import OrderToServiceTrigger from '@/components/site/order-to-service/OrderToServiceTrigger';

function start() {
  new Vue({
    el: '.widget-order-to-service-trigger',
    render: h => h(OrderToServiceTrigger)
  })
}

export default {
  start
}
