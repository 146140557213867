<template>
  <div class="order-to-service-form section"> 
    <validation-observer v-slot="{valid, invalid}">
      <p class="title is-4">Запись на сервис</p>
      
      <b-notification class="is-success" v-if="isSuccess" :closable="false">
        <p class="title is-4">Спасибо! Ваша заявка отправлена.</p>
        <p class="success-message">как только получим вашу заявку, обязательно перезвоним</p>
        <p>
          <button class="button" @click.prevent="closeSuccess">понятно</button>
        </p>
      </b-notification>
  
      <b-notification class="is-danger" v-if="hasErrors">
        <p class="title is-4">Ошибка!</p>
        <p>не удалось обработать запрос, попробуйте еще раз, если ошибка повторяется перезагрузите страницу</p>
      </b-notification>
        
      <form class="form" id="os-form" @submit.prevent="submit" ref="os_form" name="os_form" v-if="isNotSuccess">
        <input type="hidden" name="WEB_FORM_ID" value="6">
        <input type="hidden" name="form_text_54" value="да">
        <div class="columns">
          <div class="column">
            <validation-provider v-slot="{valid, dirty, invalid}" rules="required">
              <b-field :type="{'is-danger': invalid && dirty, 'is-success': valid }">
                <b-input name="form_text_51" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия *"></b-input>
              </b-field>
            </validation-provider>
          </div>
          <div class="column">
            <validation-provider v-slot="{valid, invalid, dirty}" rules="required">
              <b-field :type="{'is-danger': invalid && dirty, 'is-success': valid }">
                <b-input name="form_text_52" :disabled="isLock" v-model="formData.phone" placeholder="Телефон *"></b-input>
              </b-field>
            </validation-provider>
          </div>
        </div>
        
        <validation-provider v-slot="{invalid, valid, dirty}" rules="required">
          <b-field :type="{'is-danger': invalid && dirty, 'is-success': valid }">
            <b-input name="form_textarea_53" :disabled="isLock" v-model="formData.comment" type="textarea" placeholder="Комментарий *"></b-input>
          </b-field>
        </validation-provider>
           
        <div class="buttons mt-3">
          <b-field>
            <button class="button is-primary" type="submit" :disabled="isLock || invalid" :class="{ 'is-loading': isLock }">
              отправить            
            </button>
          </b-field>
        </div>
  
        <p>
          Заполните и отправьте свои контактные данные, <strong>мы перезвоним Вам</strong>.
        </p> 
        <p class="help">
          Отправляя форму, Вы принимаете <a href="https://docs.google.com/document/d/1viTlWxg8VNGRd1ylIkJvWyIRztEqBEwNGgWmzJI8Zrw/edit" target="_blank">Положение на обработку персональных данных и получение информации</a>. Передаваемая
          контактная информация используется исключительно для связи с Вами.
        </p>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import store from '@/store/index'
import { mapGetters, mapMutations } from 'vuex'
export default {
  store,
  data () {
    return {
      status: 'pristine',
      formId: 6,
      formData: {
        name: '',
        phone: '',
        comment: ''
      }
    }
  },
  computed: {
    ...mapGetters(['isLock']),
    isSuccess() {
      return this.status === 'success'
    },
    hasErrors() {
      return this.status === 'error'
    },
    isNotSuccess() {
      return this.status !== 'success'
    }
  },
  methods: {
    ...mapMutations(['lock', 'unlock']),
    submit () {
      this.lock()
      const formData = new FormData(this.$refs.os_form)
      
      this.$http.post('/platform/api/forms/6/submit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.unlock()
        this.status = 'success'
        
        this.formData.name = ''
        this.formData.phone = ''
        this.formData.comment = ''

        this.$parent.$emit('success')
      }, errors => {
        this.unlock()
        this.status = 'error'
        this.$buefy.toast.open({
          type: 'is-danger',
          message: `Ошибка! ${errors.status}`
        })
      })
    },
    closeSuccess() {
      this.status = 'pristine'
      this.$emit('close')
    }
  }
}
</script>
<style lang="sass" scoped>
@import 'vars'
.order-to-service-form.section
  padding: 1.4rem
.order-to-service-form
  background: #fff
  border-radius: 6px
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem

</style>
