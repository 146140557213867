<template lang="pug">
  // форма запроса обратного звонка
  .platform-callback-form.section: validation-observer(v-slot="{valid, invalid}")
    p.title.is-4 Обратный звонок
    b-notification.is-success(v-if="isSuccess" :closable="false")
      p.title.is-4 Спасибо! Ваша заявка отправлена.
      p.success-message как только получим вашу заявку, обязательно перезвоним
      p: button.button(@click.prevent="closeSuccess") понятно
    b-notification.is-danger(v-if="hasErrors")
      p.title.is-4 Ошибка!
      p не удалось обработать запрос, попробуйте еще раз, если ошибка повторяется перезагрузите страницу
    form.form(@submit.prevent="submit" ref="cb_form" name="ca_form" v-if="isNotSuccess")
      input(type="hidden" name="WEB_FORM_ID" value="3")
      input(type="hidden" name="form_text_36" value="да")
      .columns
        .column: validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_text_33" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия")
        .column: validation-provider(v-slot="{valid, invalid, dirty, errors}" rules="required")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_text_34" :disabled="isLock" v-model="formData.phone" placeholder="Телефон")
        .column.is-narrow
          b-field
            button.button.is-primary(type="submit" :disabled="isLock || invalid" :class="{ 'is-loading': isLock }") отправить
      p Заполните и отправьте свои контактные данные, <strong>мы перезвоним Вам</strong>.
      p.help.
        Отправляя форму вы соглашаетесь на обработку ваших персональных данных
        в соответствие с <a href="/agreement/" target="_blank">пользовательским соглашением сайта</a>. Передаваемая
        контактная информация используется исключительно для связи с Вами.

</template>
<script>
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
export default {
  store,
  data () {
    return {
      status: 'pristine',
      formId: 3,
      formData: {
        name: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapGetters(['isLock']),
    isSuccess() {
      return this.status === 'success'
    },
    hasErrors() {
      return this.status === 'error'
    },
    isNotSuccess() {
      return this.status !== 'success'
    }
  },
  methods: {
    ...mapMutations(['lock', 'unlock']),
    submit () {
      this.lock()
      const form = new FormData(this.$refs.cb_form)
      this.$http.post('/platform/api/forms/3/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.unlock()
        this.status = 'success'
        this.formData.name = ''
        this.formData.phone = ''

        this.$parent.$emit('success')
      }, errors => {
        this.unlock()
        this.status = 'error'
        this.$buefy.toast.open({
          type: 'is-danger',
          message: `Ошибка! ${errors.status}`
        })
      })
    },
    closeSuccess() {
      this.status = 'pristine'
      this.$emit('close')
    }
  }
}
</script>
<style lang="sass" scoped>
@import 'vars'
.platform-callback-form.section
  padding: 1.4rem
.platform-callback-form
  background: #fff
  border-radius: 6px
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem

</style>
