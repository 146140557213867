import Swiper from 'swiper'

// require('swiper/swiper.scss')
// require('swiper/css/swiper.css')
require('./style.scss')

function start() {
  /**
   * @see https://swiperjs.com/api/
   */

  const galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        //centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      });
      
    return new Swiper('.gallery-top', {
        spaceBetween: 10,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          320: {
            spaceBetween: 0,
          },
        },
        thumbs: {
        swiper: galleryThumbs
      }
      });
}

export default {
  start
}
