<template lang="pug">
  .order-to-service-trigger.button.is-danger(@click.prevent="showForm")
    span.icon: i.fas.fa-cogs
    span Записаться на ремонт
</template>
<script>
  import OrderToServiceForm from '@/components/site/order-to-service/OrderToServiceForm'
  import store from '@/store'

  /**
   * кнопка триггер для
   * отображения формы записи на ремонт
   *
   * <div class="widget-order-to-service-trigger"></div>
   */
  export default {
    store,
    methods: {
      showForm() {
        this.$buefy.modal.open({
          parent: this,
          component: OrderToServiceForm
        })
      }
    }
  }
</script>
