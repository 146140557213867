import Vue from 'vue'
import VueResource from 'vue-resource'

function init() {
  Vue.use(VueResource)
}

export default {
  init
}
