
import Vue from 'vue'
import store from '../../store'
import { CallbackForm } from '../../components'

import { gsap, TweenMax } from "gsap"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(CSSRulePlugin, ScrollToPlugin)

import * as ScrollMagic from "scrollmagic"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"

ScrollMagicPluginGsap(ScrollMagic, TweenMax)

/**
 * инициализация 
 */ 
function start () {
  /**
   * элемент должен существовать
   */ 
  if (!document.getElementById('navbar-top')) {
    console.warn('runtime:navbar:warn #navbar-top not found')
    return
  }

  /* eslint no-unused-vars: 0 */
  const app = new Vue({
    store,
    /**
     * элемент должен существовать
     * на странице
     */ 
    el: '#navbar-top',
    data: function(){
      return {
        active: false
      }
    },
    methods: {
      /**
       * добавление класса
       * к меню при клике на `burger`
       */ 
      toggle() {
        this.active = !this.active
        if (this.active) {
         document.querySelector('html').classList.add('under-menu')
       } else {
         document.querySelector('html').classList.remove('under-menu')
        }
      },
      callback () {
        const modal = this.$buefy.modal.open({
          parent: this,
          component: CallbackForm,
          canCancel: ['x'],
          width: 720
        })

        /**
         * после того, как данные успешно отправлены
         * на сервер, необходимо скрыть кнопку зарытия окна
         * вынудив пользователя нажать кнопку "ok", чтобы
         * явно сбросить настройки формы.
         *
         * Другой вариант полность разрушать форму
         * при закрытии destroyOnHide: true
         */
        modal.$on('success', () => {
          // modal.$props.canCancel = false
        })
      }
    }
  })
  
  /**
   * scrollmagic
   *
   * для запуска функциональности добавить к nav.navbar
   * класс `navbar-sticky`. после прикрепления панели
   * добавляется класс `is-pinned`
   *
   */
  let sm_selector = '.navbar-sticky'
  let pinnedClass = 'is-pinned'
  let zIndex = 39
  let navbar = document.querySelector(sm_selector)
  
  if (!navbar) return

  const controller = new ScrollMagic.Controller({})
  const menuSceneOffset = 50
  const menuScene = new ScrollMagic.Scene({
    triggerElement: sm_selector,
    triggerHook: 'onLeave',
    offset: menuSceneOffset,
    duration: 0
  })
    .setClassToggle(sm_selector, pinnedClass)
    .setPin(sm_selector)
    .on('enter', function(e) {
      TweenMax.to(sm_selector, 0.3, {
        duration: 0.3,
        y: menuSceneOffset - 1,
        zIndex: zIndex
      })
    })
    .on('leave', function(e) {
      TweenMax.to(sm_selector, 0.2, {
        y: 0,
        zIndex: zIndex
      })
    })

  // async init
  setTimeout(function(){
    menuScene.addTo(controller)
  }, 100)
}

export default {
  start
}
