import Vue from 'vue'
import Cleave from 'cleave.js'
import 'cleave.js/src/addons/phone-type-formatter.ru'

function init() {
  Vue.directive('cleave', {
    inserted: (el, binding) => {
      el.cleave = new Cleave(el, binding.value || {})
    },
    update: (el) => {
      const event = new Event('input', {bubbles: true})
      setTimeout(function(){
        el.value = el.cleave.properties.result
        el.dispatchEvent(event)
      }, 100)
    }
  })
}

export default {
  init
}
