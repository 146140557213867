<template lang="pug">
  // форма в контактах
  .hero-contact-form.hero.is-primary: .hero-body: .container: validation-observer(v-slot="{valid, invalid}")
    p.title.is-5.has-text-centered Отправьте нам сообщение
    b-notification.is-success.content(v-if="isSuccess" :closable="false")
      p.title.is-5 Спасибо! Ваша заявка отправлена.
      p: button.button(@click.prevent="closeSuccess") понятно
    b-notification.is-danger(v-if="hasErrors")
      p.title.is-4 Ошибка!
      p не удалось обработать запрос, попробуйте еще раз, если ошибка повторяется перезагрузите страницу
    form.form(@submit.prevent="submit" ref="fc_form" name="fc_form" v-if="isNotSuccess")
      input(type="hidden" name="WEB_FORM_ID" value="1")
      input(type="hidden" name="form_text_39" value="да")
      .columns.is-multiline
        .column.is-half: validation-provider(v-slot="{valid, dirty, invalid, pristine, errors}" rules="required" name="Имя и фамилия")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_text_41" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия *")
          p.help.is-danger(v-if="invalid")  {{ errors[0] }}  
        .column.is-half: validation-provider(name="Телефон")
          b-field
            b-input(name="form_text_38" v-model="formData.phone" placeholder="Телефон")
          p.help.is-danger(v-if="invalid") 
        .column.is-half: validation-provider(v-slot="{errors, valid, dirty, invalid, pristine }" rules="required|email" name="Email")
          b-field(:type="{'is-danger': invalid && dirty, 'is-success': valid }")
            b-input(name="form_email_2" :disabled="isLock" v-model="formData.email" placeholder="Email *")
          p.help.is-danger(v-if="invalid")  {{ errors[0] }}
        .column.is-half: validation-provider()
          b-field
            b-input(name="form_text_55" v-model="formData.theme" placeholder="Тема сообщения")
      validation-provider(v-slot="{errors, valid, dirty, invalid, pristine }" rules="required" name="Ваше сообщение")
        b-field
          b-input(name="form_textarea_1" type="textarea" placeholder="Ваше сообщение * " v-model="formData.massage")
        p.help.is-danger: span(v-if="invalid")  {{ errors[0] }}    
      p.has-text-centered: b-field
        button.button.is-danger(type="submit" :disabled="isLock || invalid" :class="{ 'is-loading': isLock }") отправить
      p.help.has-text-centered * - обязательные поля.
      p.help.has-text-centered.
        Отправляя форму, Вы принимаете <a href="https://docs.google.com/document/d/1viTlWxg8VNGRd1ylIkJvWyIRztEqBEwNGgWmzJI8Zrw/edit" target="_blank">Положение на обработку персональных данных и получение информации</a>. Передаваемая
        контактная информация используется исключительно для связи с Вами.

</template>
<script>
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
export default {
  store,
  data () {
    return {
      status: 'pristine',
      formId: 1,
      formData: {
        name: '',
        email: '',
        theme: '',
        massage: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapGetters(['isLock']),
    isSuccess() {
      return this.status === 'success'
    },
    hasErrors() {
      return this.status === 'error'
    },
    isNotSuccess() {
      return this.status !== 'success'
    }
  },
  methods: {
    ...mapMutations(['lock', 'unlock']),
    submit () {
      this.lock()
      const form = new FormData(this.$refs.fc_form)
      this.$http.post('/platform/api/forms/1/submit', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.unlock()
        this.status = 'success'
        this.formData.name = ''
        this.formData.phone = ''
        this.formData.email = ''
        this.formData.theme = ''
        this.formData.massage = ''

        this.$parent.$emit('success')
      }, errors => {
        this.unlock()
        this.status = 'error'
        this.$buefy.toast.open({
          type: 'is-danger',
          message: `Ошибка! ${errors.status}`
        })
      })
    },
    closeSuccess() {
      this.status = 'pristine'
      this.$emit('close')
    }
  }
}
</script>
<style lang="sass" scoped>
@import 'vars'
.hero.hero-contact-form
  background-image: url("/local/templates/platform/assets/images/bg_contacts.jpg")
  background-position: center center
  background-size: cover
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem

</style>
