<template lang="pug">
  .callback-trigger.button.is-primary(@click.prevent="showForm")
    // span.icon: i.fas.fa-cogs
    span Заказать звонок
</template>
<script>
  import CallbackForm from '@/components/site/callback/CallbackForm.vue'
  import store from '@/store'

  /**
   * кнопка триггер для
   * отображения формы записи на ремонт
   *
   * <div class="widget-callback-trigger"></div>
   * @see ./index.js
   */
  export default {
    store,
    methods: {
      showForm() {
        this.$buefy.modal.open({
          parent: this,
          component: CallbackForm
        })
      }
    }
  }
</script>
