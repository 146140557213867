<template lang="pug">
  .platform-feedback-form
    form.form(@submit.prevent="submit" ref="form")
      // обязательный атрибут, по этому атрибуту
      // битрикс понимает какая форма отправлена со страницы
      input(type="hidden" name="WEB_FORM_ID" value="")
      // имена полей уникальны в одной установки Битрикс
      // Битрикс полчает список полей по ID формы и затем
      // выбирает поля конкретной формы из объекта $_REQUEST
      b-field(label="Имя"): b-input(name="form_text_33" required)
      b-field(label="Фамилия"): b-input(name="form_text_34")
      b-button(type="is-primary" @click.prevent="submit" :class="{'is-loading': isLock}") отправить
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isLock'])
  },
  methods: {
    submit () {
      // const form = new FormData(this.$refs.form)
      // this.$http.post('/platform/', form, {})
      this.$store.commit('lock')
      setTimeout(() => {
        this.$store.commit('unlock')
      }, 3000)
    }
  }
}
</script>
<style lang="sass">

</style>
