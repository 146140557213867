import $ from 'jquery'
require('./style.scss')

function start() {
  /**
   * управление "табами" на детальной странице
   * каталога товаров (пример: /catalog/car-alarm/starline-a63/)
   */
  $('.tabs.tabs-elem-catalog ul li').on('click', function(){
    var tabId = $(this).attr('data-tab');
    $('.tabs.tabs-elem-catalog ul li').removeClass('is-active');
    $('.tab-content.tabs-elem-catalog-content').removeClass('is-active');
    $(this).addClass('is-active');
    $(`.tab-content.tabs-elem-catalog-content#${tabId}`).addClass('is-active');
  })
}

export default {
   start
}
