
function start() {
  /**
   * выпадающее меню наших работ
   */
   const elem = document.querySelector('.dropdown.dropdown-work-menu')
   if (elem) {
    elem.onclick=function(){
      elem.classList.toggle("is-active");
    }
  } 
}

export default {
   start
}
