<template lang="pug">
  .hello-world-component.section
    h1.title Hello World!
    .columns
      .column: b-field: b-button(type="is-primary") is-primary button
      .column: b-field: b-button(type="is-danger") is-danger button
      .column: b-field: b-button(type="is-info") is-info button
      .column: b-field: b-button(type="is-success") is-success button
    .columns
      .column: b-button(@click="$buefy.toast.open({ message: 'Ok! Success.', type: 'is-success'})")
        span.icon: i.fas.fa-window-maximize
        span toast (on top)
      .column: b-button(@click="$buefy.modal.open({})")
        span.icon: i.fas.fa-window-maximize
        span modal window
    .columns
      .column: b-notification(type="is-success").
        Компания Google объявила о том, что теперь пользователи смогут отключать
        навязчивые рекламные объявления, если они видели их уже большое количество раз.
        Об этом разработчики сообщили в своем блоге.
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.section {
  background: #eee;
}

</style>
